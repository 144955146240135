import { handleHengenixSclcLimitedStageCustomRecommendationLogic } from './handleHengenixSclcLimitedStageCustomRecommendationLogic'

// TODO - remove these hardcoded trials once we have a better way to handle this
export const EFFECTOR_NSCLC = {
  nctId: 'NCT04622007',
  slug: 'phase-3-carcinoma-non-small-cell-lung-4-2021-91b28',
}
export const IGM_BIO_TRIAL = {
  nctId: 'NCT04553692',
  slug: 'phase-1-chondrosarcoma-8-2020-e60f6',
}
export const HENGENIX_SCLC_LIMITED_STAGE = {
  nctId: 'NCT05353257',
  slug: 'phase-3-limited-stage-small-cell-lung-cancer-4-2022-bf289',
}
export const HENGENIX_SCLC_EXTENSIVE_STAGE = {
  nctId: 'NCT05468489',
  slug: 'phase-3-extensive-stage-small-cell-lung-cancer-sclc-8-2022-951a0',
}
export const LUNGEVITY_NEOPLASMS = {
  nctId: 'NCT05826756',
  slug: 'phase-lung-neoplasms-7-2020-46aaf',
}

export const INHIBRX_NSCLC = {
  nctId: 'NCT04198766',
  slug: 'phase-1-carcinoma-transitional-cell-11-2019-fd3bb',
}

export const CULLINAN_NSCLC = {
  nctId: 'NCT04036682',
  slug: 'phase-2-carcinoma-non-small-cell-lung-9-2019-feea2',
}

export const TECLISON_NSCLC = {
  nctId: 'NCT04701476',
  slug: 'phase-3-lung-neoplasms-4-2021-8d841',
}

export const ONCOC4_NSCLC = {
  nctId: 'NCT05671510',
  slug: 'phase-3-lung-neoplasms-4-2023-ab690',
}

export const LANTERN_NSCLC = {
  nctId: 'NCT05456256',
  slug: 'phase-2-carcinoma-non-small-cell-lung-6-2022-b92c8',
}

export const MINDMED_GAD_PHASE_3 = {
  nctId: 'MMM120300',
}

/**
 * Handle the custom case logic for various stripped colloquial terms.
 */
export default function handleRecommendationsByColloquialTermStripped({
  currentTrialNctId,
  patientConditionColloquialTermsStripped = [],
  strippedColloquialTerms,
}: {
  currentTrialNctId?: string
  patientConditionColloquialTermsStripped?: string[]
  strippedColloquialTerms: string[]
}) {
  // Special hard-coded case for v0 of the recommendations logic
  if (currentTrialNctId === HENGENIX_SCLC_LIMITED_STAGE.nctId) {
    return handleHengenixSclcLimitedStageCustomRecommendationLogic(
      strippedColloquialTerms,
    )
  }

  if (
    strippedColloquialTerms.length === 0 &&
    patientConditionColloquialTermsStripped.length === 0
  ) {
    return []
  }

  const lowerPatientConditionColloquialTermsStripped =
    patientConditionColloquialTermsStripped.map((t) => t.toLowerCase())

  const isLungCancerRelatedTrial = [
    'small cell lung cancer',
    'non-small cell lung cancer',
    'lung adenocarcinoma',
    'lung carcinoma',
    'lung cancer',
  ].some((t) => lowerPatientConditionColloquialTermsStripped.includes(t))

  // Special case when a patient applies to a lung-cancer trial but doesn't match on a condition
  // We suggest the more general and monetized NSCLC trial
  if (strippedColloquialTerms.length === 0 && isLungCancerRelatedTrial) {
    return [EFFECTOR_NSCLC, LUNGEVITY_NEOPLASMS]
  }

  const lowerStrippedColloquialTerms = strippedColloquialTerms.map((t) =>
    t.toLowerCase(),
  )

  if (lowerStrippedColloquialTerms.includes('non-small cell lung cancer')) {
    return [EFFECTOR_NSCLC, LUNGEVITY_NEOPLASMS]
  } else if (lowerStrippedColloquialTerms.includes('small cell lung cancer')) {
    return [
      EFFECTOR_NSCLC,
      HENGENIX_SCLC_LIMITED_STAGE,
      HENGENIX_SCLC_EXTENSIVE_STAGE,
      LUNGEVITY_NEOPLASMS,
    ]
  } else if (
    lowerStrippedColloquialTerms.includes('lung adenocarcinoma') ||
    lowerStrippedColloquialTerms.includes('lung carcinoma') ||
    lowerStrippedColloquialTerms.includes('lung cancer')
  ) {
    return [EFFECTOR_NSCLC, HENGENIX_SCLC_LIMITED_STAGE, LUNGEVITY_NEOPLASMS]
  }

  return []
}
